import { type ClientLoaderFunctionArgs, useLoaderData } from '@remix-run/react';
import { useTitle } from 'react-use';

import {
  CustomGamePackEditor,
  CustomGamePackLayout,
  UGCFileManagerProvider,
} from '../components/Game/UGC';
import { getFeatureQueryParam } from '../hooks/useFeatureQueryParam';
import { apiService } from '../services/api-service';
import { makeTitle } from '../utils/common';
import { tokenWithRedirect } from '../utils/router';

export async function clientLoader(action: ClientLoaderFunctionArgs) {
  const url = new URL(action.request.url);
  const { id } = action.params;
  if (!id) throw new Response('Not Found', { status: 404 });

  const [packResp, ugcFilesResp] = await Promise.all([
    tokenWithRedirect(
      () =>
        apiService.gamePack.getGamePackById(id, {
          blocks: true,
          brands: true,
          linkedSharedAssets: true,
        }),
      action.request.url
    ),
    tokenWithRedirect(
      () => apiService.gamePack.getUGCFiles(id),
      action.request.url
    ),
  ]);

  return {
    ...packResp.data,
    ugcFiles: ugcFilesResp.data.files,
    created: url.searchParams.get('created') === 'true',
  };
}

export function Component() {
  const { gamePack, blocks, linkedSharedAssets, created, ugcFiles } =
    useLoaderData<typeof clientLoader>();
  useTitle(makeTitle(`Customize ${gamePack.name}`));
  return (
    <UGCFileManagerProvider>
      <CustomGamePackLayout>
        <CustomGamePackEditor
          pack={gamePack}
          blocks={blocks ?? []}
          assets={linkedSharedAssets ?? []}
          ugcFiles={ugcFiles}
          created={created}
          enableAI={getFeatureQueryParam('game-pack-ugc-ai')}
        />
      </CustomGamePackLayout>
    </UGCFileManagerProvider>
  );
}
